var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { Flex } from '../Flex';
import FormField from './FormField';
var getDefaultValues = function (formGroups) {
    return formGroups.reduce(function (acc, field) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[field.name] = field.initialValue, _a)));
    }, {});
};
var isFieldFormGroup = function (field) { return !!(field === null || field === void 0 ? void 0 : field.name); };
var Form = styled(Flex)({
    boxSizing: 'border-box',
    flexDirection: 'column',
});
// eslint-disable-next-line max-lines-per-function
var DynamicForm = function (_a) {
    var id = _a.id, onSubmit = _a.onSubmit, ariaLabel = _a.ariaLabel, formConfig = _a.formConfig, formGroups = _a.formGroups, formStyles = _a.formStyles, onFormChange = _a.onFormChange, fieldsToWatch = _a.fieldsToWatch, triggerValidation = _a.triggerValidation, renderSubmitButton = _a.renderSubmitButton, onWatchedFieldChange = _a.onWatchedFieldChange, triggerValidationOnMount = _a.triggerValidationOnMount, _b = _a.isDisabledWhileSubmitting, isDisabledWhileSubmitting = _b === void 0 ? false : _b;
    var _c = useForm(__assign({ mode: 'all', reValidateMode: 'onChange', defaultValues: getDefaultValues(formGroups.filter(isFieldFormGroup)) }, formConfig)), formState = _c.formState, methods = __rest(_c, ["formState"]);
    var isValidating = formState.isValidating, isValid = formState.isValid, isDirty = formState.isDirty, isSubmitting = formState.isSubmitting;
    useEffect(function () {
        if (onFormChange && !isValidating) {
            var formData = methods.getValues();
            var formDataMap = new Map(Object.entries(formData));
            onFormChange({
                formData: formDataMap,
                isValid: isValid,
                isDirty: isDirty,
            });
        }
    }, [isValidating, isValid, isDirty]);
    useEffect(function () {
        var subscription;
        if (fieldsToWatch && fieldsToWatch.length > 0 && onWatchedFieldChange) {
            subscription = methods.watch(function (value, _a) {
                var name = _a.name;
                var fieldName = name;
                if (name && fieldsToWatch.includes(fieldName)) {
                    var changedValue = value === null || value === void 0 ? void 0 : value[fieldName];
                    var triggerFieldIfDirty = function (fieldToTrigger) {
                        var isFieldToTriggerDirty = methods.getFieldState(fieldToTrigger).isDirty;
                        if (isFieldToTriggerDirty) {
                            methods.trigger(fieldToTrigger);
                        }
                    };
                    onWatchedFieldChange(fieldName, changedValue !== null && changedValue !== void 0 ? changedValue : null, {
                        setValue: methods.setValue,
                        trigger: methods.trigger,
                        triggerFieldIfDirty: triggerFieldIfDirty,
                    });
                }
            });
        }
        return function () { return subscription === null || subscription === void 0 ? void 0 : subscription.unsubscribe(); };
    }, [fieldsToWatch, onWatchedFieldChange, methods.watch]);
    useEffect(function () {
        if (triggerValidationOnMount) {
            methods.trigger();
        }
    }, [triggerValidationOnMount, methods.trigger]);
    useEffect(function () {
        if (triggerValidation && triggerValidation > 0) {
            methods.trigger();
        }
    }, [triggerValidation, methods.trigger]);
    return (React.createElement(FormProvider, __assign({ formState: formState }, methods),
        React.createElement(Form, __assign({ id: id, as: "form", noValidate: true, "aria-label": ariaLabel }, (onSubmit && { onSubmit: methods.handleSubmit(onSubmit) }), formStyles), formGroups.map(function (field, index) {
            return isFieldFormGroup(field) ? (React.createElement(FormField, __assign({ key: field.name, isLastField: index === formGroups.length - 1, isDisabledWhileSubmitting: isDisabledWhileSubmitting }, field))) : (field);
        })), renderSubmitButton === null || renderSubmitButton === void 0 ? void 0 :
        renderSubmitButton({
            form: id,
            type: 'submit',
            isDisabled: !isValid,
            isFormDirty: isDirty,
            isLoading: isSubmitting,
        })));
};
export default DynamicForm;
