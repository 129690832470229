var getMaxDay = function (_a) {
    var month = _a.month, year = _a.year;
    if (!month)
        return 31;
    if (['1', '3', '5', '7', '8', '10', '12'].includes(month)) {
        return 31;
    }
    else if (['4', '6', '9', '11'].includes(month)) {
        return 30;
    }
    else {
        if (!year)
            return 28;
        var isLeap = new Date(parseInt(year, 10), 1, 29).getMonth() === 1;
        return isLeap ? 29 : 28;
    }
};
var validateDay = function (r) { return function (value) {
    var day = parseInt(value.day, 10);
    var max = getMaxDay(value);
    if (isNaN(day) || day < 1 || day > max)
        return r.t('h24_birthday_day_error', { max: max });
    return true;
}; };
var validateYear = function (t) { return function (value) {
    var YEAR_INPUT_LENGTH = 4;
    var year = parseInt(value.year, 10);
    if (isNaN(year) || value.year.length !== YEAR_INPUT_LENGTH)
        return t('h24_birthday_year_error');
    return true;
}; };
var validateMonth = function (t) { return function (value) {
    var month = parseInt(value.month, 10);
    if (isNaN(month) || month < 1 || month > 12)
        return t('h24_birthday_month_error');
    return true;
}; };
var areFieldsValid = function (t) { return function (value) {
    if (!value.day || !value.month || !value.year)
        return false;
    if (typeof validateMonth(t)(value) === 'string' ||
        typeof validateYear(t)(value) === 'string' ||
        typeof validateDay({ t: t })(value) === 'string')
        return false;
    return true;
}; };
var isDateValid = function (value) {
    var month = parseInt(value.month, 10) - 1;
    var day = parseInt(value.day, 10);
    var year = parseInt(value.year, 10);
    var date = new Date(year, month, day);
    if (date.getFullYear() === year && date.getMonth() === month && date.getDate() === day) {
        return date;
    }
    return false;
};
var validateDate = function (t) { return function (value) {
    if (!areFieldsValid(t)(value))
        return true;
    if (!isDateValid(value))
        return t('h24_birthday_date_error');
    return true;
}; };
var getBirthdayFieldValidators = function (_a) {
    var t = _a.t, r = _a.r;
    return ({
        day: validateDay(r),
        month: validateMonth(t),
        year: validateYear(t),
        date: validateDate(t),
    });
};
export var getBirthdayFieldValidations = function (_a) {
    var t = _a.t, r = _a.r, gqlValidationRules = _a.gqlValidationRules;
    return ({
        required: false,
        rules: gqlValidationRules,
        functions: getBirthdayFieldValidators({ t: t, r: r }),
    });
};
export var getValidDate = function (t) { return function (value) {
    if (!areFieldsValid(t)(value))
        return null;
    var date = isDateValid(value);
    return date ? date : null;
}; };
