export var FieldType;
(function (FieldType) {
    FieldType["Checkbox"] = "checkbox";
    FieldType["Email"] = "email";
    FieldType["Password"] = "password";
    FieldType["Radio"] = "radio";
    FieldType["Select"] = "select";
    FieldType["Tel"] = "tel";
    FieldType["Text"] = "text";
    FieldType["TextArea"] = "textarea";
})(FieldType || (FieldType = {}));
export var ValidationRuleType;
(function (ValidationRuleType) {
    ValidationRuleType["AllOf"] = "ValidationRuleAllOf";
    ValidationRuleType["AnyOf"] = "ValidationRuleAnyOf";
    ValidationRuleType["Not"] = "ValidationRuleNot";
    ValidationRuleType["Pattern"] = "ValidationRulePattern";
    ValidationRuleType["Age"] = "ValidationRuleAge";
})(ValidationRuleType || (ValidationRuleType = {}));
