var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { REGEX_CITY, REGEX_COMPANY, REGEX_LETTERS_DOTS_AND_COMMAS_ONE_LETTER, REGEX_STREET_AND_NUMBER, } from '../../../constants/form';
import { FieldName } from '../../../enums/address';
var getFieldRegexPattern = function (field) {
    return new Map([
        [FieldName.FirstName, REGEX_LETTERS_DOTS_AND_COMMAS_ONE_LETTER],
        [FieldName.LastName, REGEX_LETTERS_DOTS_AND_COMMAS_ONE_LETTER],
        [FieldName.StreetAndNumber, REGEX_STREET_AND_NUMBER],
        [FieldName.City, REGEX_CITY],
        [FieldName.Company, REGEX_COMPANY],
    ]).get(field);
};
export var removeFirstAndLastSlashes = function (value) {
    return value.replace(/^\/|\/$/g, '');
};
var getAllowedCharacters = function (regex) {
    return new RegExp(regex).toString().replace(/[/^$]/g, '');
};
export var getInvalidCharacters = function (value, regex) {
    return __spreadArray([], __read(new Set(value.replace(regex, ''))), false).join(', ');
};
export var getInvalidCharErrorDetails = function (field, value) {
    var fieldRegexPattern = getFieldRegexPattern(field);
    return (fieldRegexPattern &&
        value &&
        getInvalidCharacters(value, new RegExp(removeFirstAndLastSlashes(getAllowedCharacters(fieldRegexPattern)), 'g')));
};
