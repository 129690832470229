export var FieldName;
(function (FieldName) {
    // only for business accounts
    FieldName["Company"] = "company";
    // regular address fields
    FieldName["Title"] = "title";
    FieldName["FirstName"] = "firstName";
    FieldName["LastName"] = "lastName";
    FieldName["Phone"] = "phone";
    FieldName["StreetAndNumber"] = "streetAndNumber";
    FieldName["DigiCode"] = "digicode";
    FieldName["ShowAddressLine2"] = "showAddressLine2";
    FieldName["AddressLine2"] = "addressLine2";
    FieldName["Zip"] = "zip";
    FieldName["City"] = "city";
    FieldName["IsBillingSameAsShipping"] = "isBillingSameAsShipping";
    // billing address specific fields
    FieldName["CountryCode"] = "countryCode";
    // tax-id specific fields
    FieldName["SendInvoiceViaEmail"] = "sendInvoiceViaEmail";
    FieldName["TaxId"] = "taxId";
    // guest checkout specific fields
    FieldName["EmailAddress"] = "emailAddress";
    FieldName["RegisterAccount"] = "registerAccount";
    FieldName["Password"] = "password";
    FieldName["NewsletterSubscription"] = "newsletterSubscription";
})(FieldName || (FieldName = {}));
