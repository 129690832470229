import dayjs from 'dayjs';
import { getValidDate } from '../../Form/BirthdayField/validations';
import { ValidationRuleType } from '../types';
import { getPatternErrorMessage } from './get-error-message';
import { inputIsMatching } from './regex-helpers';
var validateNestedRules = function (_a) {
    var rules = _a.rules, inputValue = _a.inputValue, translate = _a.translate;
    var nestedRules = rules === null || rules === void 0 ? void 0 : rules.filter(function (rule) { return 'rules' in rule; });
    if (!!(nestedRules === null || nestedRules === void 0 ? void 0 : nestedRules.length)) {
        return nestedRules.map(function (rule) { return createValidator(rule, translate)(inputValue); });
    }
    return null;
};
var findErrorMessage = function (validatorNestedRules) {
    return validatorNestedRules === null || validatorNestedRules === void 0 ? void 0 : validatorNestedRules.find(function (validator) { return typeof validator === 'string'; });
};
var validatePattern = function (_a) {
    var rule = _a.rule, inputValue = _a.inputValue, translate = _a.translate;
    return inputIsMatching(rule.value, inputValue) ||
        getPatternErrorMessage({ rule: rule, inputValue: inputValue, translate: translate });
};
var validateNot = function (_a) {
    var rules = _a.rules, inputValue = _a.inputValue, translate = _a.translate;
    var nestedValidationResults = validateNestedRules({
        rules: rules,
        inputValue: inputValue,
        translate: translate,
    });
    var nestedRulesErrorMsg = findErrorMessage(nestedValidationResults);
    var failingPatternRule = rules === null || rules === void 0 ? void 0 : rules.find(function (rule) { return 'value' in rule && inputIsMatching(rule.value, inputValue); });
    if (!failingPatternRule && !nestedRulesErrorMsg) {
        return true;
    }
    return nestedRulesErrorMsg || (failingPatternRule === null || failingPatternRule === void 0 ? void 0 : failingPatternRule.errorMessage);
};
var validateAllOf = function (_a) {
    var rules = _a.rules, inputValue = _a.inputValue, translate = _a.translate;
    var nestedValidationResults = validateNestedRules({
        rules: rules,
        inputValue: inputValue,
        translate: translate,
    });
    var nestedRulesErrorMsg = findErrorMessage(nestedValidationResults);
    var failingPatternRule = rules === null || rules === void 0 ? void 0 : rules.find(function (rule) { return 'value' in rule && !inputIsMatching(rule.value, inputValue); });
    if (!failingPatternRule && !nestedRulesErrorMsg) {
        return true;
    }
    return (nestedRulesErrorMsg ||
        getPatternErrorMessage({
            rule: failingPatternRule,
            inputValue: inputValue,
            translate: translate,
        }));
};
var validateAnyOf = function (_a) {
    var rules = _a.rules, inputValue = _a.inputValue, translate = _a.translate;
    var nestedValidationResults = validateNestedRules({
        rules: rules,
        inputValue: inputValue,
        translate: translate,
    });
    var isAnyOfNestedRulesValid = nestedValidationResults === null || nestedValidationResults === void 0 ? void 0 : nestedValidationResults.some(function (isValid) { return isValid === true; });
    var isAnyOfValid = rules === null || rules === void 0 ? void 0 : rules.some(function (rule) { return 'value' in rule && inputIsMatching(rule.value, inputValue); });
    var nestedRulesErrorMsg = findErrorMessage(nestedValidationResults);
    if (isAnyOfValid || isAnyOfNestedRulesValid) {
        return true;
    }
    return (nestedRulesErrorMsg ||
        getPatternErrorMessage({
            rule: rules[0],
            inputValue: inputValue,
            translate: translate,
        }));
};
var validateAge = function (_a) {
    var rules = _a.rules, inputValue = _a.inputValue, translate = _a.translate;
    var date = getValidDate(translate)(inputValue);
    if (date && rules.min && rules.max) {
        var isWithinRange = !(dayjs().diff(date, 'year') < rules.min) && !(dayjs().diff(date, 'year') > rules.max);
        return isWithinRange || rules.errorMessage;
    }
    return true;
};
var createValidator = function (rule, translate) {
    return function (inputValue) {
        switch (rule.ruleType) {
            case ValidationRuleType.Pattern:
                return validatePattern({
                    rule: rule,
                    inputValue: inputValue,
                    translate: translate,
                });
            case ValidationRuleType.Not:
                return validateNot({
                    rules: rule.rules,
                    inputValue: inputValue,
                    translate: translate,
                });
            case ValidationRuleType.AllOf:
                return validateAllOf({
                    rules: rule.rules,
                    inputValue: inputValue,
                    translate: translate,
                });
            case ValidationRuleType.AnyOf:
                return validateAnyOf({
                    rules: rule.rules,
                    inputValue: inputValue,
                    translate: translate,
                });
            case ValidationRuleType.Age:
                return validateAge({
                    rules: rule,
                    inputValue: inputValue,
                    translate: translate,
                });
            default:
                return true;
        }
    };
};
export var getFormFieldValidators = function (rules, translate) {
    var validators = {};
    rules === null || rules === void 0 ? void 0 : rules.forEach(function (rule, index) {
        validators["".concat(rule.ruleType).concat(index)] = createValidator(rule, translate);
    });
    return validators;
};
