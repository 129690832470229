var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getValidationErrorKey } from '../../constants/form';
import { LengthValidatorType } from '../../enums/ValidatorType';
import { useLocalization } from '../../providers/LocaleProvider';
import { FieldInfo } from '../Form/FieldInfo';
import FormGroupRow from '../Form/FormGroupRow';
import { getFormFieldValidators } from './utils/validate-form-field';
var formFieldStyle = {
    mb: 'sp_0',
};
// eslint-disable-next-line react/display-name
var InputForwardRef = forwardRef(function (_a, forwardedRef) {
    var Component = _a.Component, props = __rest(_a, ["Component"]);
    return React.createElement(Component, __assign({}, props, { inputRef: forwardedRef }));
});
var FormField = function (_a) {
    var _b;
    var name = _a.name, Component = _a.Component, ComponentProps = _a.ComponentProps, helpText = _a.helpText, bottomContent = _a.bottomContent, isLastField = _a.isLastField, isDisabledWhileSubmitting = _a.isDisabledWhileSubmitting, field = __rest(_a, ["name", "Component", "ComponentProps", "helpText", "bottomContent", "isLastField", "isDisabledWhileSubmitting"]);
    var t = useLocalization().t;
    var methods = useFormContext();
    return (React.createElement(Controller, { name: name, control: methods.control, rules: {
            required: field.validation.required &&
                ((_b = field.validation.requiredErrorMessage) !== null && _b !== void 0 ? _b : t(getValidationErrorKey(name, LengthValidatorType.Length))),
            validate: __assign(__assign({}, getFormFieldValidators(field.validation.rules, t)), field.validation.functions),
        }, 
        // eslint-disable-next-line
        // @ts-ignore
        defaultValue: field.initialValue, render: function (_a) {
            var fieldProps = _a.field, error = _a.fieldState.error, formState = _a.formState;
            return (React.createElement(FormGroupRow, __assign({}, (isLastField && { mb: 'sp_0' })),
                React.createElement(InputForwardRef, __assign({ Component: Component }, ComponentProps, fieldProps, formFieldStyle, (field.label && { label: field.label }), (isDisabledWhileSubmitting && { disabled: formState.isSubmitting }), { "aria-invalid": !!error, hasError: !!error, "aria-required": field.validation.required }, ((error === null || error === void 0 ? void 0 : error.message) && {
                    errors: [
                        field.validation.rulesErrorMessage
                            ? field.validation.rulesErrorMessage
                            : error.message,
                    ],
                }), ((error === null || error === void 0 ? void 0 : error.types) && { errorsTypes: error.types }), { formState: formState })),
                !!helpText && React.createElement(FieldInfo, { mt: "sp_4", info: helpText }),
                !!bottomContent && bottomContent));
        } }));
};
export default FormField;
